var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"",staticStyle:{"max-width":"950px"}},[_c('v-col',{staticClass:"mt-7 col-5"},[_c('associatedOption',{attrs:{"fieldAttributes":{
                    name: 'Org List',
                    associatedOption:_vm.result.organisationList,
                },"type":"combobox","value":_vm.selectedVal,"fieldAttrInput":{
                        multiple: true,
                        chips: true,
                        class: ' ',
                        style: '',
                        id:' '
                    }},scopedSlots:_vm._u([{key:"selection",fn:function(slotProps){return [_c('v-chip',{attrs:{"label":"","x-small":"","close":""},on:{"click:close":function($event){return _vm.removeValue(slotProps.item)}}},[_c('span',[_vm._v(_vm._s(slotProps.item.text))])]),_c('input',{attrs:{"type":"hidden","name":_vm.field + '[]'},domProps:{"value":slotProps.item.value}})]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }