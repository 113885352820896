<template>
    <v-row style="max-width: 950px;" class=" ">
        <v-col class="mt-7 col-5">
             <associatedOption
                    :fieldAttributes="{
                        name: 'Org List',
                        associatedOption:result.organisationList,
                    }"
                    type="combobox"
                    :value="selectedVal"
                     :fieldAttrInput="{
                            multiple: true,
                            chips: true,
                            class: ' ',
                            style: '',
                            id:' '
                        }"

            >
                <template v-slot:selection="slotProps">
                    <v-chip
                            label
                            x-small
                            close
                            @click:close="removeValue(slotProps.item)"
                    >
            <span>{{
              slotProps.item.text
            }}</span>
                    </v-chip>
                    <input
                            type="hidden"
                            :name="field + '[]'"
                            :value="slotProps.item.value"
                    />
                </template>
            </associatedOption>

          <!--  <input type="hidden" name="orgSelected" :value="JSON.stringify(selectedItem.organisationSelected)">-->
        </v-col>
     </v-row>
</template>
<script>
  import associatedOption from "@/commonComponents/associatedOption.vue";
  export default {
    components: {
      associatedOption,
    },
    data: function () {
      return {
        selectedVal: this.result.valueLine.userOrgList,
      };
    },
    props: ["result", "field", "fieldAttributes", "value"],
    methods: {
      removeValue(item) {
        var clickedIndex = this.selectedVal.findIndex(function(set) {
          return set.value === item.value;
        });
        this.selectedVal.splice(clickedIndex, 1);
      },
      updateFilter(value, text, info) {
        this.selectedVal= info;
      },
    },
  };
</script>

